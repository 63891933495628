/*-------------------------------------------*/
/* FONTS */
/*-------------------------------------------*/
@font-face{font-family: Coda-Regular;src:url('../fonts/Coda-Regular.ttf');}
@font-face{font-family: Poppins-Bold;src:url('../fonts/Poppins-Bold.otf');}
@font-face{font-family: Poppins-Medium;src:url('../fonts/Poppins-Medium.otf');}


$default_font: 'Coda-Regular', sans-serif;
$custom_font: 'Poppins-Bold', sans-serif;
$custom_font2: 'Poppins-Medium', sans-serif;
$medium_font_weight : 500;
$light_font_weight : 300;
$lg_font_size : 18px;
$md_font_size : 16px;
$sm_font_size : 13px;
$xs_font_size : 12px;
/*-------------------------------------------*/
/* COLORS */
/*-------------------------------------------*/
$color-1 	: #F59E2C;
$color-2 	: #00487C;
$color-3 	: #001E5E;