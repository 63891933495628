/*IMPORT SCSS*/
/*-------------------------------------------*/
@import "mixins";
@import "variables";
/*-------------------------------------------*/
@-webkit-keyframes imagezoom {
 50% {
  -webkit-transform:scale(1.2);
  -moz-transform:scale(1.2);
  -ms-transform:scale(1.2);
  -o-transform:scale(1.2);
  transform:scale(1.2)
 }
}
@-moz-keyframes imagezoom {
 50% {
  -webkit-transform:scale(1.2);
  -moz-transform:scale(1.2);
  -ms-transform:scale(1.2);
  -o-transform:scale(1.2);
  transform:scale(1.2)
 }
}
@keyframes imagezoom {
 50% {
  -webkit-transform:scale(1.2);
  -moz-transform:scale(1.2);
  -ms-transform:scale(1.2);
  -o-transform:scale(1.2);
  transform:scale(1.2)
 }
}
section#inner-banner {
	img {
		&.upper-img {
			position: absolute;
			top: 0;
			left: 0;
			width: 80%;
			z-index: -1;
		}
		&.blue-img {
			position: absolute;
			height: 100%;
			width: 75%;
			top: 0;
			right: 0;
			z-index: -2;
		}
	}
	h1 {letter-spacing: 3px;margin-right: -3px;}
}
.hand-cursor {cursor : pointer;}
.hide-me {display: none;}
.spacer {
	padding-top: 80px;
	padding-bottom: 80px;
}
.sm-margin {
	margin-right: -8px;
	margin-left: -8px;
	& > div {
		padding-left: 8px;
		padding-right: 8px;
	}
}
.xs-margin {
	margin-right: -4px;
	margin-left: -4px;
	& > div {
		padding-left: 4px;
		padding-right: 4px;
	}
}
.xxs-margin {
	margin-right: -3px;
	margin-left: -3px;
	& > div {
		padding-left: 3px;
		padding-right: 3px;
	}
}
.mt-15 { margin-top: 15px; }
.mt-30 { margin-top: 30px; }
.mt-50 { margin-top: 50px; }
.ml-xs-40 {margin-left: 40px;}
.spacer-bottom {padding-bottom: 80px;}
.spacer-top {padding-top: 80px;}
.hide-me {display: none;}
.p-relative {
	position: relative;
}
.img-fit {
	object-fit: cover;
	object-position: center;
}
.img-contain {
	object-fit: contain;
	object-position: center;
}
.section-title {
	/*padding-bottom: 20px;*/
	position: relative;
	font-weight: 400;
	&:before {
        content: "";
	    background: url(../images/icons/fire.png);
	    background-repeat: no-repeat;
	    background-size: contain;
	    background-position: center;
	    position: absolute;
	    height: 30px;
	    width: 35px;
	}

	/*&:after {
		content: "";
		display: block;
		border-top: 4px solid #333;
		position: absolute;
		bottom: 0;
		width: 35px;
		left: 0;
	}*/
	/*&.border-up {
		&:before, &:after {
			bottom: auto;
			top: 0;
		}
	}*/
	&.text-center:before {   
		margin-left: -45px;
	}
	&.text-left:before {
		right: auto;
    	left: -45px;
	}
}
.bigger {width: 250px !important;}
.default-btn {
	display: inline-block;
    padding: 15px;
    text-align: center;
    position: relative;
    width: 150px;
    max-width: 100%;
    text-transform: capitalize;
  	font-family: $default_font !important;
  	letter-spacing: 1px;
  	span {
  		z-index: 2;	
		display: block;
		position: relative;
  	}
  /*	&:before {
  		content: '';
	    position: absolute;
	    top: 0;
	    left: 0;
	    width: 0;
	    height: 100%;
	    z-index: 1;
	    opacity: 0;
		@include transition-time(0.3s);
  	}*/
  	&.white-btn {
  		background-color: #fff;
  		&:before {
  			background-color: $color-2;
  		}
  		&:hover, &.active {
  			color: #fff !important;
  		}
  	}
   	&.solid-orange {
  		  background: $color-1;
		  color: #fff;
		  display: inline-block;
		  overflow: hidden;
         position: relative;
  		&:before {
  			  background: $color-3;
			  content: '';
			  position: absolute;
			  z-index: 1;
  		}
  	   &:after {
  			  background: $color-3;
			  content: '';
			  position: absolute;
			  z-index: 1;
			  height : 120%;
			  left: -35%;
			  top: 0;
			  transform: skew(38deg);
			  transition-duration: 0.6s;
			  transform-origin: top left;
			  width: 0;
  		}
  		&:hover, &.active {
  			color: #fff;

  			&:after {
  				  height: 120%;
  				  width: 135%;
  			}
  		}
  		
  	}
  	&.bordered {
  		  background: none;
		  color: $color-1;
		  display: inline-block;
  		  border: 2px solid $color-1;
		  overflow: hidden;
         position: relative;
  		&:before {
  			  background: $color-1;
			  content: '';
			  position: absolute;
			  z-index: -1;
  		}
  	   &:after {
  			  background: $color-1;
			  content: '';
			  position: absolute;
			  z-index: 1;
			  /*background-color: #f00;*/
			  height : 120%;
			  left: -35%;
			  top: 0;
			  transform: skew(38deg);
			  transition-duration: 0.6s;
			  transform-origin: top left;
			  width: 0;
  		}
  		&:hover, &.active {
  			color: #fff;
  			border-color: $color-1;

  			&:after {
  				  height: 120%;
  				  width: 135%;
  			}
  		}
  		
  	}
   &:hover, &.active {
		&:before {
			width: 100%;
			opacity: 1;
		}
	}
}
.default-accdn {
	.accdn-item {
		/*background-color: #fff;
		box-shadow: 0 0 4px rgba(153, 153, 153, 0.3);*/
		&:not(:last-child){
			margin-bottom: 24px;
		}
		.accdn-head {
			padding: 30px 15px;
			font-size: $sm_font_size;
			color: #333;
			position: relative;
			
			h6:after {
				content: '>';
				position: absolute;
				margin-left: 15px;
				/*top: 28px;*/
				background: $color-1;
				color: #fff;
				padding: 1px 4px 0px 4px;
				@include transition-time(0.3s);
				font-family: $custom-font2 !important;
			}
			&.active {
				font-size: $md_font_size;
				pointer-events: none;
				h6:after {
					content: ">";
					@include transform-rotate(-90deg);
				}
			}
			&:not(.active) {
				cursor: pointer;
			}
			&:hover {
				color: $color-1;
				h6:after {
					content: ">";
				}
			}
			
		}
		.accdn-content {
			padding: 0 20px 15px 15px;
			display: none;
			&.active {
				display: block;
			}

		}
	}
}
.custom-accdn {
	.accdn-item {
		&:not(:last-child){
			margin-bottom: 24px;
		}
		.accdn-head {
			padding: 15px 15px 15px 0;
			font-size: 16px;;
			color: $color-1;
			position: relative;
			font-weight: bold;
			border-bottom: 2px solid #ccc;
			&:after {
				content: url("../images/icons/plus-gray.png");
				position: absolute;
				right: 15px;
				top: 15px;
				@include transition-time(0.3s);
			}
			&.active {
				pointer-events: none;
				&:after {
					content: url("../images/icons/minus-gray.png");
				}
				&:hover {
					&:after {
						content: url("../images/icons/minus-active.png");
					}
				}
			}
			&:not(.active) {
				cursor: pointer;
			}
			&:hover {
				color: $color-1;
				&:after {
					content: url('../images/icons/plus-active.png');
				}
			}
			
		}
		.accdn-content {
			padding: 0 20px 15px 0;
		}
	}
}
.bulleted-list {
	list-style: none;
	li {
		position: relative;
		padding-left: 20px;
		&:not(:last-child) {
			margin-bottom: 24px;
		}
		&:before {
			content: "";
			display: block;
			height: 7px;
			width: 7px;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 5px;
			background-color: $color-1;
		}
	}
}
.arrowed-list {
	list-style: none;
	li {
		position: relative;
		padding-left: 20px;
		&:not(:last-child) {
			margin-bottom: 24px;
		}
		&:before {
			content: ">";
			display: block;
			height: 7px;
			width: 7px;
			border-radius: 100%;
			position: absolute;
			left: 0;
			top: 0;
			color: $color-1;
		}
	}
}
.square-list {
	list-style: none;
	line-height: 1.5;
	li {
		position: relative;
		padding-left: 20px;
		&:not(:last-child) {
			margin-bottom: 24px;
		}
		&:before {
			content: "";
			display: block;
			height: 7px;
			width: 7px;
			position: absolute;
			left: 0;
			top: 5px;
			background-color: $color-1;
			@include transform-rotate(45deg);
		}
	}
}
.dashed-list {
	list-style: none;
	li {
		position: relative;
		padding-left: 20px;
		&:not(:last-child) {
			margin-bottom: 5px;
		}
		&:before {
			content: "-";
			position: absolute;
			left: 0;
			top: 0;
		}
	}
}
.ordered-list {
	list-style: none;
	counter-reset: ol;
	li {
		position: relative;
		padding-left: 20px;
		&:not(:last-child) {
			margin-bottom: 24px;
		}
		&:before {
			counter-increment: ol;
    		content: counter(ol)".";
			display: block;
			position: absolute;
			left: 0;
			top: 0;
		}
	}
	&.page1-2 {
		counter-reset: ol 5;
	}
}
@media (max-width: 991px) {

}
@media (max-width: 767px) {
	.default-btn {
        padding: 12px !important;
        width: 120px;
    }
    .spacer {
    	padding-top: 40px;
    	padding-bottom: 40px;
    }
    .spacer-top {
    	padding-top: 40px;
    }
    .spacer-bottom {
    	padding-bottom: 40px;
    }
	.section-title.text-left:before {
	    left: -45px;
	    top: 7px;
	}
}
@media (min-width: 576px) {

}
@media (max-width: 575px) {
	.section-title.text-left:before {
   	    top: -5px;
	}
	.section-title.text-center::before {
   		margin-left: -35px;
   	}
}