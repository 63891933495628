/*-------------------------------------------*/
/*IMPORT SCSS*/
/*-------------------------------------------*/
@import "mixins";
@import "variables";
@import 'common';
@import 'menu';
/*-------------------------------------------*/
/*BEGIN FONT CSS*/
/*-------------------------------------------*/
@import url('https://fonts.googleapis.com/css?family=Montserrat:100,100i,200,200i,300,300i,400,400i,500,500i,600,600i,700,700i,800,800i,900,900i|Roboto+Condensed:300,300i,400,400i,700,700i|Roboto:100,100i,300,300i,400,400i,500,500i,700,700i,900,900i');
/*END FONT CSS*/
/*-------------------------------------------*/
/*BEGIN GLOBAL CSS*/
/*-------------------------------------------*/
* {
	border: 0;
	padding: 0;
	margin: 0;
    letter-spacing: 1px;
}
html {
    overflow-x: hidden;
}
body {
	white-space: normal;
	word-wrap: break-word;
	font-family: $default_font;
	color: #666;
	font-size: 13px;
	line-height: 1.2;
    overflow: hidden;
    position: relative;
    &::before {
     content: "";
        pointer-events: none;
        position: fixed;
        left: 50%;
        top: 50%;
        height: 100%;
        width: 100%;
        background: #fff url('../images/loader.gif') center center no-repeat no-repeat;
            background-size: auto;
        background-size: 100px;
        z-index: 999999999;
       @include transform_translate(-50%,-50%);
       @include transition-time(1s);
       opacity: 0;
    }
        &.loading-content{
        &::before {
         opacity: 1;
        }
    }
}
h1, h2, h3, h4, h5, h6 {line-height: 2;}
.container-fluid {width: 100%;max-width: 1920px;padding-left: 100px;padding-right: 100px;}
.plc {padding-left: 100px;}
.prc {padding-right: 100px;}
.md-container {max-width: 1120px;}
hr {border-top: 2px solid #343434;}
p {font-family: $default_font; line-height: 2;}
.small-margin {
    margin-left : -10px;
    margin-right: -10px;
    & > div {
        padding-left: 10px;
        padding-right: 10px;
    }
}
.double-line-height {line-height: 2;}
.add-letter-space {letter-spacing: 1px;}
.hid-overflow {overflow: hidden;}
small {font-size: 12px;}
.font-medium {font-weight: $medium_font_weight;}
.lg_font_size {font-size : $lg_font_size;}
.sm_font_size {font-size: $sm_font_size;}
.md_font_size {font-size: $md_font_size;}
.xs_font_size {font-size: $xs_font_size;}
.default-font {font-family: $default_font;}
.custom-font {font-family: $custom_font;}
.custom-font2 {font-family: $custom_font2;}
.onehalf-line {line-height: 1.5;}
.d-hide {display: none !important;}
.d-show {display: block !important;}
a {
	color: inherit;
	@include transition-time(0.3s);
    &:hover, &:focus, &:visited {
        text-decoration: none !important;
        outline: none !important;
    }
    &:hover {
        color : $color-1;
    }
}
b, strong {font-weight: bold;}
.font-semibold {font-weight: 600;}
.small-text {font-size: 12px;letter-spacing: 1px;}
.lg_text {font-size: 16px;}
img {
    @include user-select(none);
}
          


.fancybox-close-small, .fancybox-close-small:hover, .fancybox-close-small:focus, .fancybox-close-small:active {   
    &:after {
        outline: 0 !important;
        right: 10px;
        top: 10px;
    }
}
/*-------------------------------------------*/
/*FLEXSILDER*/
/*-------------------------------------------*/
.flex-control-paging {
    bottom: 30px !important;
    z-index: 2;
    a {
        display: inline-flex !important;
        height: 10px;
        width: 10px;
        background: #fff !important;
        border-radius: 100%;
        margin-right: 8px;
        box-shadow: none !important;
            &.flex-active {
                background: $color-1 !important;
                border: 0;
                margin-right: 55px;
                height: 10px;
                width: 10px;
                color: transparent;
                &:before {
                   border: 1px solid #fff !important;
                    content: ' ' !important;
                    width: 16px;
                    height: 16px;
                    border-radius: 20px;
                    position: absolute;
                    margin-left: -3px;
                    top: -3px;
                }
        
                &:after 
                {   
                    border-top: 1px solid #fff !important;
                    position: absolute;
                    content: ' ' !important;
                    width: 50px;
                    top: 5px;
                    margin-left: 20px;
                    visibility: visible;
                    -webkit-animation-duration: 1s;
                    -webkit-animation-fill-mode: both;
                    animation-duration: 1s;
                    animation-fill-mode: both;
                    -webkit-animation-name: fadeInRight;
                    animation-name: fadeInRight;
                }
            } 
        }
}
/*-------------------------------------------*/
/*OWL CAROUSEL*/
/*-------------------------------------------*/
.owl-carousel .owl-stage-outer {
    padding-bottom: 50px;
}
.owl-nav {
    display: none;
}
.owl-dots {
    position: absolute;
    bottom: 0;
    width: 100%;
    text-align: center;
    line-height: 0;
    .owl-dot {
        display: inline-block;
        height: 8px;
        width: 8px;
        background-color: #857F74;
        border-radius: 100%;
        margin-right: 8px;
        @include transition-time(0.5s);
        &.active {
            background-color: $color-1;
            border: 0;
            margin-right: 55px;
            height: 8px;
            width: 8px;
            &:before {
                border: 1px solid gray;
                content: ' ';
                width: 14px;
                height: 14px;
                border-radius: 20px;
                position: absolute;
                margin-left: -7px;
                top: -3px;
            }
            &:after {
                width: 40px;

                visibility: visible;
                -webkit-animation-duration: 3s;
                -webkit-animation-fill-mode: both;
                animation-duration: 3s;
                animation-fill-mode: both;
                -webkit-animation-name: fadeIn;
                animation-name: fadeIn;
            }
        }
        &:after {
            border-top: 1px solid gray;
            position: absolute;
            content: ' ';
            width: 0;
            top: 4px;
            margin-left: 12px;
        }
        &:hover {
            background-color: $color-1;
        }
        &:last-child {
            margin-right: 0;
        }
    }

}

/*-------------------------------------------*/
/*BEGIN COLOR CSS*/
/*-------------------------------------------*/
.color-1 {color: $color-1;}
.color-2 {color: $color-2;}
.color-3 {color: $color-3;}
.white-color{color: #fff;}
.black-color{color: #000;}
.light-gray {color: #666;}
.dark-gray {color: #333;}

/*END COLOR CSS*/
/*-------------------------------------------*/
/*BEGIN CONTENT CSS*/
/*-------------------------------------------*/
.typed-cursor {visibility: hidden;}
h1,h2,h3,h4,h5,h6,ul,p {
	margin: 0;
}
h1,h2,h3,h4,h5,h6 {
	font-weight: normal;
	line-height: 1.3;
}
section {
	float: left;
	width: 100%;
	position: relative;
}
ul li {list-style-type: none;}
.section-wrapper {
    max-width: 1920px;
    margin: 0 auto;
}
#main {
	float: left;
	width: 100%;
    @include transition-time(0.3s);
}
button, button:focus {outline:0 !important; font-family: $default_font !important; cursor: pointer;}
#innerpage #main{ margin-top: 86px; }
.ulists {
   padding: 15px;
           
    li{
        padding-bottom: 15px;

        &:before {
        background: url(../images/icons/lists.png);
            background-repeat: no-repeat;
            background-size: contain;
            content: ' ';
            position: absolute;
            height: 30px;
            width: 30px;
            margin-top: -4px;
        }

        p{
            padding-left: 40px;
        }
    }
}

.modal-header .close {
    font-size: 40px !important;
    margin-right: -18px;
    margin-top: -28px;
    color: #f59e2c;
}
/*ABOUT*/
#about-01 {
    .img-right-holder {
        background: url('../images/about/img-01.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
    }
}
#about-02 {
    box-shadow: 2px 2px 15px 0 #d5d3d3;
}
#about-03 {
    .img-left-holder {
        background: url('../images/about/img-02.jpg');
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center;
        width: 100%;
    }
    .icons {
        padding-right: 15px;
        width: 90px;
    }
 
}
#about-04 {
    .tab-menu{
        ul.default-tab {
                display: flex;
                justify-content: center;
                align-items: baseline;

                 li {
                    cursor: pointer;
                    width: 230px;
                    padding: 10px;
                    text-align: center;
                    height: 200px;
                    margin-left: 10px;
                    margin-right: 10px;

                    img {
                        margin: 30px auto 20px;
                        display: block;
                        filter: grayscale(100%);
                    }

                    &:hover, &.active {
                        box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.07);
                        border-bottom: 10px solid $color-1;
                       
                        img {
                            filter: none;
                        }

                        h5 {
                            color: $color-1;
                        }

                    } 
               }
            }
    }

    .tab-content {
        max-width: 1100px;
        margin: 0 auto;
    }

}
/*PRODUCTS & SERVICES*/
#product {
    .flexslider { border: 0 !important; }
    .tab-menu {
        ul.default-tab {
                display: flex;
                justify-content: center;
                align-items: baseline;

                 li {
                    cursor: pointer;
                    width: 530px;
                    padding: 10px;
                    text-align: center;
                    height: 570px;
                    margin-left: 10px;
                    margin-right: 10px;

                    img {
                        margin: 30px auto 20px;
                        display: block;
                        filter: grayscale(100%);
                    }

                    &:hover, &.active {
                        box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.07);
                        border-bottom: 10px solid $color-1;
                       
                        img {
                            filter: none;
                        }

                        h5 {
                            color: $color-1;
                        }

                    } 
               }
            }
    }

    .tab-content {
        .first-row  {
            .outer { position: relative; margin:0 auto; max-width:800px;}
            .owl-carousel .owl-stage-outer { padding-bottom: 0; }
            .main-carousel.owl-theme { position: relative; }
            .main-carousel.owl-theme .owl-nav  { display: none; }
            .main-carousel .item { margin:2px; color: #FFF; border-radius: 3px; text-align: center; }
            .thumb-carousel .item {height: 100%; line-height:70px; padding: 0px; margin:2px; color: #FFF; border-radius: 3px; text-align: center; cursor: pointer; }
            .owl-theme .owl-nav [class*='owl-'] { -webkit-transition: all .3s ease; transition: all .3s ease; }
            .owl-theme .owl-nav [class*='owl-'].disabled i{ color: #ccc !important; }
            .owl-nav {
                position: absolute;
                top: 50%;
                display: block;
                left: 50%;
                width: 100%;
                @include transform_translate(-50%, -50%);
                .owl-next {
                    position: absolute;
                    top: -15px;
                    right: -35px;
                   
                    i {
                        font-size: 35px;
                        display: inline-block;
                        line-height: 40px;
                        color: #F59E2C;

                        @include transition-time(0.3s);
                       
                        &:hover {
                            color: #0021fd;
                        }
                    }


                }
                .owl-prev {
                    position: absolute;
                    top: -15px;
                    left: -35px;

                    i {
                        font-size: 35px;
                        display: inline-block;
                        line-height: 40px;
                        color: #F59E2C;

                        @include transition-time(0.3s);
                        
                        &:hover {
                            color: #0021fd;
                        }
                    }

                }
            }

            button#viewBtn {
                background: transparent;
                color: #f59e2c;
            }
      
        }
    
    }

}
/*PORJECTS*/
#projects {

    .img-holder
    {
        position: relative;
        overflow: hidden;
        .overlay {
            display: flex;
            align-items: center;
            position: absolute;
            bottom: 20px;
        }
        .first, .third {
            background: url(../images/projects/title-border-right.png);
            width: 100%;
            height: 87px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: right;
            right: 0;
            bottom: 0;
            justify-content: flex-end;
            .title { 
                color: #fff;
                width: 100%;
                padding-left: 160px;
                text-align: center;

                &:after {

                    content: '';
                    border-bottom: 1px solid #fff;
                    position: absolute;
                    bottom: 25px;
                    width: 60%;
                    right: 110px;
                    margin-left: 28px;
                }

             }

            .icon {
                width: 40px;
                margin: 10px 35px;
            }
        }
        .second {
            background: url(../images/projects/title-border-left.png);
            width: 100%;
            height: 87px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: left;
            justify-content: flex-start;
            left: 0;
            bottom: 0;

            .title { 
                color: #fff;
                width: 100%;
                padding-right: 160px;
                text-align: center;

                &:after {
                    content: '';
                    border-bottom: 1px solid #fff;
                    position: absolute;
                    bottom: 25px;
                    width: 60%;
                    margin-left: -14px;
                    left: 110px;
                }

             }

            .icon {
                width: 40px;
                margin: 10px 35px;
            }
        }
    }
}

/*CONTACT*/

#contact-01 {
    .con-text {
        padding-left: 20px;
    }
    .form-holder {
        box-shadow: 0 1px 10px 5px rgba(0, 0, 0, 0.07);
        padding: 50px;
    }
}


/*-------------------------------------------*/
/*END CONTENT CSS*/




/*-------------------------------------------*/
/*BEGIN FOOTER CSS*/
footer {
    float: left;
    width: 100%;
    z-index: 1;
    position: relative;
    background-color: #001E5E;

    #copyright {
        padding-top: 20px;
        padding-bottom: 20px;
        p{
            color: #fff;
            font-family: $custom_font2 !important;
            font-size: 12px;
        }
        a > i {
            color: #fff;
            font-size: 25px;
            margin-left: 10px;
            margin-right: 10px;
            line-height: 1.5;
            @include transition-time(0.3s);
            &:hover {
                color: $color-1;
            }
        }
        img.hover.insta-icon {
                position: absolute;
                top: 19px;
                margin-left: -22px;
                opacity: 0;
                @include transition-time(0.3s);

                &:hover {
                    opacity: 1;
                }
            }
        img.insta-icon {
            margin-top: -12px;
            width: 22px;

            &:hover {
                opacity: 0;

            }
        }
    }
}

/*END FOOTER CSS*/
/*-------------------------------------------*/
/*-------------------------------------------*/
.select-wrapper {
    position: relative;
    &:after {
        content: url('../images/icons/arrow-down.png');
        position: absolute;
        right: 10px;
        top: calc(50% - 12px);
        @include transform_translate(0,-50%);
        z-index: 1;
    }
    select {
        position: relative;
        z-index: 2;
    }
}
input, textarea, select, button {
	@include appearance(none);
    border-radius: 0 !important;
    outline : none !important;
    font-size: 13px !important;
}
.lower-part input:not([type=checkbox]):not([type=submit]):not([type=file]), .lower-part select {
    background-color: transparent;
}
input:focus, input:hover, textarea:focus, textarea:hover, select:focus, select:hover, button:hover, button:focus {
    outline: none !important;
}
input:not([type=checkbox]):not([type=submit]):not([type=file]):not([type=radio]), textarea, select {
    width: 100%;
    border: 1px solid #eee !important;
    padding: 25px 15px;
    background-color: transparent;
    margin-bottom: 10px;
    box-shadow: none !important;
    color: #333;
    font-size: 13px !important;
}
textarea {
    height: 110px;
    resize: none;
}
/*input:not([type=checkbox]):not([type=radio]):not([type=submit]):not([type=file]):hover, input:not([type=checkbox]):not([type=file]):not([type=submit]):focus, textarea:hover, textarea:focus, select:hover,select:focus {
    background-color: #e2e4e4;
}*/
input[type=checkbox], input[type=radio] {
    width: 34px;
    height: 30px;
    border: 1px solid #666;
    margin-right: 10px;
    vertical-align: middle;
    background-color: transparent !important;
    &:checked {
        border-color : $color-1;
        background: url('../images/icons/tick.png') center center no-repeat no-repeat;
        background-size: 75%;
    }
}
button[type=submit] {cursor: pointer;}

.tab-content > div:not(.active) {display:none;}


/*BEGIN FORM CSS*/
.parsley-errors-list li {
	list-style: none;
	color: red;
	font-size: 13px;
	margin-top: 0;
	margin-bottom: 15px;
}
::-webkit-input-placeholder { /* Chrome/Opera/Safari */
  color: #eee;
  font-family: $default_font;
  opacity: 1;
}
::-moz-placeholder { /* Firefox 19+ */
  color: #eee;
  font-family: $default_font;
  opacity: 1;
}
:-ms-input-placeholder { /* IE 10+ */
  color: #eee;
  font-family: $default_font;
  opacity: 1;
}
:-moz-placeholder { /* Firefox 18- */
  color: #eee;
  font-family: $default_font;
  opacity: 1;
}
#product .tab-content .first-row .owl-nav.disabled {
        display: none;
    }
/*-------------------------------------------*/
/*-------------------------------------------*/
/* Smartphone and Tablet view */
@media (min-width: 1520px){
    body {
        font-size: 16px;
    }
}
@media (max-width: 1299px) {
    .container-fluid {
        padding-left: 50px;
        padding-right: 50px;
    }
    .plc {padding-left: 50px;}
    .prc {padding-right: 50px;}

}
@media (max-width: 1199px) { /* 1200px large */	
    .container-fluid {
        padding-left: 30px;
        padding-right: 30px;
    }
    .plc {padding-left: 30px;}
    .prc {padding-right: 30px;}

}
@media (max-width: 1199px) and (min-width: 768px) {
}
@media (max-width: 991px) { /* 992px medium */
    .container {
        width: 100%;
    }
    .default-btn {
        padding: 14px;
    }
    #about-01 .first-row .img-right-holder,
    #about-01 .sec-row .img-left-holder {
        height: 700px;
        margin: 15px;
    }
    #about-01 .img-right-holder, #about-03 .img-left-holder { 
        height: 570px;    
        margin-left: 15px;
        margin-right: 15px;
    }
}
@media (max-width: 767px) { /* 768px small */
    .container-fluid {
        padding-left: 15px;
        padding-right: 15px;
    }
    .plc {padding-left: 15px;}
    .prc {padding-right: 15px;}
    h2.h1, h2 {font-size: 36px;}
    .thumb-carousel {
        padding-bottom: 35px;
    }
    #product .tab-menu ul.default-tab li {
        width: 100%;
        height: 315px;
        img {
            width: 50%;
        }
        h5 {
            font-size: 16px;
        }
    }
    #projects .img-holder {
        padding-left: 15px !important;
        padding-right: 15px !important;
        margin-bottom: 20px;
    }
    #projects .img-holder .first, #projects .img-holder .third {
        width: 98%;
        height: 50px;
        right: 15px;
    }
 
    #product .tab-content .first-row .thumb-carousel .item {
        height: 100%;
    }
    #projects .img-holder .second {
        width: 98%;
        height: 50px;
        left: 15px;
    }
    #projects .img-holder .second .title:after {  margin-left: -3px;  bottom: 14px;}
    #projects .img-holder .first .title:after, #projects .img-holder .third .title:after {  bottom: 14px; }
    .title {
        font-size: 15px;
    }
    .btn-links {text-align: center;}
    .btn-links a {
        display: inline-block !important;
        width: 100%;
    }
    .bars { padding: 10px; }
    .ulists {
         padding: 0; 
    }
    .flexslider.thumb-slider {
        margin-bottom: 0;
    }
    p { text-align: justify; }
    #about-02 .tab-menu ul.default-tab li { margin: 0 auto;}
     .btn-links {  display: block !important;  }
     .btn-links a:first-child { margin-top: 30px;}
     .btn-links a:last-child { margin-top: 20px;}
     .subtle { padding-bottom: 25px;}
     
}
@media (max-width: 575px) { /* 600px */
    h2, h2.h1 {font-size: 24px !important;}
    h3 {font-size: 22px !important;}
     #product .tab-content .first-row .main-slider .slides img {
        height: 350px;
    }
    #projects .img-holder .first, #projects .img-holder .second, #projects .img-holder .third {
        width: 94%;
        height: 70px;
    }

     #projects .img-holder .second .title { padding-right: 90px; }
     #projects .img-holder .first .title, #projects .img-holder .third .title { padding-left: 95px;}
     #product .tab-menu ul.default-tab {  display: block;   padding: 35px 15px;}
     #product .tab-menu ul.default-tab li img { width: 35%;}
     #about-02 .tab-menu ul.default-tab { display: block;}
     #product .tab-menu ul.default-tab li { margin-left: 0; margin-right: 0; }
}
@media (max-width: 459px) { /* 460px */

    .sm_font_size {  font-size: 10px;   }
}
@media(max-width: 376px) {
    body { cursor: pointer; }
    .hidden-xxs-down {display: none !important;}
    .first, #projects .img-holder .second, #projects .img-holder .third { width: 89%; }
    #projects .img-holder .first .title, #projects .img-holder .third .title {  padding-left: 20px;}
    #projects .img-holder .second .title { padding-right: 30px; }
    #projects .img-holder .first .title::after, #projects .img-holder .second .title::after, #projects .img-holder .third .title::after { content: none; }
    #product .tab-menu ul.default-tab li { height: 230px;}
    #about-02 .tab-menu ul.default-tab li { margin-left: auto;  margin-right: auto; }
}