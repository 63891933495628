@mixin user-select($value) {
	-webkit-user-select: $value;
  	-moz-user-select: $value;
  	-ms-user-select: $value;
  	user-select: $value;  
}
@mixin appearance($value) {
	-webkit-appearance: $value;
   	-moz-appearance:    $value;
   	appearance:         $value;
}
@mixin flex($value){
  -webkit-flex: $value;
  -ms-flex: $value;
  flex: $value;
}
@mixin transform_translate($x, $y){
	-ms-transform: translate($x, $y);
    -webkit-transform: translate($x, $y);
    transform: translate($x, $y);
}
@mixin transform-origin($value){
  -ms-transform-origin: $value; /* IE 9 */
    -webkit-transform-origin: $value; /* Safari */
    transform-origin: $value;
}
@mixin transition-time($value){
	-ms-transition: all ease $value; /* IE 9 */
    -webkit-transition: all ease $value; /* Safari */
    transition: all ease $value; 
}
@mixin transform-rotate($value){
	-ms-transform: rotate($value); /* IE 9 */
    -webkit-transform: rotate($value); /* Safari */
    transform: rotate($value);
}
@mixin transform-rotateY($value){
  -ms-transform: rotateY($value); /* IE 9 */
    -webkit-transform: rotateY($value); /* Safari */
    transform: rotateY($value);
}
@mixin transform-rotateX($value){
  -ms-transform: rotateX($value); /* IE 9 */
    -webkit-transform: rotateX($value); /* Safari */
    transform: rotateX($value);
}
@mixin transform-rotateZ($value){
  -ms-transform: rotateZ($value); /* IE 9 */
    -webkit-transform: rotateZ($value); /* Safari */
    transform: rotateZ($value);
}
@mixin transform-skewY($value){
  -ms-transform: skewY($value); /* IE 9 */
    -webkit-transform: skewY($value); /* Safari */
    transform: skewY($value);
}
@mixin transform-scale($value){
  -ms-transform: scale($value); /* IE 9 */
    -webkit-transform: scale($value); /* Safari */
    transform: scale($value);
}
@mixin grayscale($value){
  -webkit-filter: grayscale($value); /* Safari 6.0 - 9.0 */
  ilter: grayscale($value);
}
@mixin column-count($value){
  -webkit-column-count: $value; /* Chrome, Safari, Opera */
  -moz-column-count: $value; /* Firefox */
  column-count: $value;
}