/*-------------------------------------------*/
/*IMPORT SCSS*/
/*-------------------------------------------*/
@import "mixins";
@import "variables";
/*-------------------------------------------*/
#top-nav {
	position: fixed;
	width: 100%;
	z-index: 4;
}
#homepage {
	.navbar {
			background: transparent;
		.navbar-brand {
			opacity: 0;
		}
	}
	.nav-scroll-change { 
	    background: #fff; 
	    box-shadow: 0 1px 5px rgba(153, 153, 153,0.7);
	    @include transition-time (.5s);

	    .navbar-brand {
	        opacity: 1 !important;
	    }
	}
}
#innerpage {
	.navbar {
		background: #fff;
	}
}
.nav-scroll-change { 
    background: #fff; 
    box-shadow: 0 1px 5px rgba(153, 153, 153,0.7);
    @include transition-time (.5s);

    .navbar-brand {
        opacity: 1 !important;
    }
}
.navbar-brand {
	/*padding: 10px 0;*/
	opacity: 1;
	img {
		height: 60px;
	}
}
#main-menu {
	ul {
		li {
			padding: 0 15px;
			position: relative;
			a {
			    color: #999;
			    position: relative;
			    display: inline-block;
			    font-size: 16px;
			    padding: 0 8px;
			    font-family: $default_font !important;
			    &:hover, &.active {
			    	color: $color-1;
			    }
			}
			&:last-child {
				padding-right: 0 !important;
				a {
					padding-right: 0 !important;
				}
			}
			ul {
				position: absolute;
				background-color: #fff;
				box-shadow: 0 0 8px rgba(153, 153, 153, 0.4);
				top: 70px;
				left: 15px;
				min-width: 240px;
				padding: 0 20px;
				overflow: hidden;
				li {
					list-style: none;
					text-align: left;
					padding-top: 0;
					padding-bottom: 0;
					padding-left: 0 !important;
					padding-right: 0 !important;
					display: block;
					&:first-child {
						padding-top: 5px !important;
					/*	&:before {
							top: -11px;
							border-bottom: 11px solid rgba(0, 0, 0, 0.2);
							border-left: 11px solid transparent;
							border-right: 11px solid transparent;
						}
						&:after {
							top: -10px;
							border-bottom: 11px solid #fff;
							border-left: 11px solid transparent;
							border-right: 11px solid transparent;
						}
						&:after, &:before {
							content: "";
							display: inline-block;
							position: absolute;
							left: 10px;
						}*/
					}
					&:last-child {padding-bottom: 5px !important;}
					&:not(:last-child){
						border-bottom: 1px solid #efefef;
					}
					a {
						padding: 10px 0;
						line-height: 1.5;
						&:hover {
							padding-left: 10px;
						}
						&.active {
							color: $color-1;
						}
					}
				}
			}
		}
	}
}
.menu-toggle {
  	cursor: pointer;
  	transition-property: opacity, -webkit-filter;
  	transition-property: opacity, filter;
  	transition-property: opacity, filter, -webkit-filter;
  	transition-duration: 0.15s;
  	transition-timing-function: linear;
  	font: inherit;
  	color: inherit;
  	text-transform: none;
  	border: 0;
  	overflow: visible;
  	.icon-bar {
	    display: block;
	    width: 22px;
	    height: 2px;
	    border-radius: 1px;
	    background-color: $color-2;
	    margin-top: 4px;
	    @include transition-time(0.3s);
	    &#i1 {
	    	@include transform-rotate(0);
	    	@include transform-origin(10% 10%);
		}
		&#i2 {
			opacity:1;
		 	filter:alpha(opacity=100);
		}
		&#i3 {
			@include transform-origin(10% 90%);
			@include transform-rotate(0);
		}
	}
	&[aria-expanded="true"] {
		#i1 {
			@include transform-rotate(45deg);
		}
		#i2 {
			opacity:0;
		 	filter:alpha(opacity=0);
		
		}
		#i3 {
			@include transform-rotate(-45deg);
			@include transform-origin(10% 90%);
		
		}
	}
}
@media (min-width: 992px) {
	#main-menu ul li ul {height: 0;@include transition-time(0.3s);}
	#main-menu ul li:hover ul.two-links {
		height: 90px;
	}
	#main-menu ul li:hover ul.seven-links {
		height: 331px;
	}
}
@media (max-width: 991px) {
	
}
@media (min-width: 768px) {
	.navbar .navbar-collapse {width: auto;}
}
@media (max-width: 767px) {

	.navbar {
	    padding-left: 0;
	    padding-right: 0;

	} 
	.navbar {
		background-color: #fff !important;
		box-shadow: 0 1px 5px rgba(153, 153, 153,0.7);
	}
	.navbar-brand {
    	opacity: 1 !important;
    }
	.navbar .navbar-collapse {margin-bottom: -16px;}

	.navbar-nav {  margin-bottom: 8px; }

	#main-menu ul li{

		a {
		    width: 100%;
		    padding-top: 10px;
		    padding-bottom: 10px;
		    height: 100%;
		 }
	   	&:active, &:hover {
	   		background: $color-1;
				a { color: #fff;}
	   	}
		
		&:last-child {
			padding-right: 15px!important;
	
			a {padding-right: 8px !important;}
		}
	}
	.navbar-nav {
		text-align: center; 
		/*padding-bottom: 10px;*/
		border-top: 1px solid #ccc;
	}
}
